<template>
  <v-app>
    <v-app-bar dark absolute  app short>
      <!-- Icon Button -->
      <v-tooltip bottom v-if="showBackButton">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="goBack" v-bind="attrs" v-on="on">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon :to="`/prompts`" v-bind="attrs" v-on="on">
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>View prompts</span>
      </v-tooltip>
      <!-- <v-toolbar-title>EYQ M&A Report Assistant</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <AuthComponent v-if="state && state.isAuthenticated" :user="state.user || {}"  :handleLogout="handleLogout" :isProd="isProd" :handleEnvironments="handleEnvironments" :admin="admin" />
    </v-app-bar>
    <snackbar ref="snackbar" />
    <LoaderComponent v-if="authenticationLoader" />
    <v-main v-else>
      <v-container v-if="state && state.isAuthenticated">
        <div v-if="modalDetails.showDialog && modalDetails.name === 'tncpopup'">
          <ModalLayout :modal="modalDetails" :updateModalDetail="updateModalDetail" :isClose="false"
            ><template v-slot:header> <span>Terms and Conditions </span></template>
            <template v-slot:body>
            <p>Welcome to <strong>EYQ M&A Report Assistant!</strong> </p>
              <p>To continue using our tool, we kindly ask you to review and accept our Terms & Conditions. Please click the button below to be redirected to the <strong>EYQ M&A Document Accelerator</strong> webpage where you can find and accept the Terms & Conditions.</p>
              <p> Once you have accepted, please return to this page and click the <strong>'Refresh'</strong> button to proceed.</p>
              <p> <strong>Thank you for your cooperation!</strong></p>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col class="d-flex flex-row-reverse">
                  <v-btn class="font-weight-bold" plain color="primary" :href="'https://eyqdocumentaccelerator-uat.ey.com/'" target="_blank">Open Webpage</v-btn>
                  <v-btn plain color="primary" @click="refreshPage">Refresh</v-btn>
                </v-col>
            </v-row>
            </template>
          </ModalLayout>
        </div>
        <router-view :admin="admin" :userId="userId" :userType="userType"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SnackbarComponent from "./components/SnackbarComponent.vue"
import AuthComponent from "./components/AuthComponent.vue"
import ModalLayout from "./components/ModalLayout.vue";
import LoaderComponent  from "./components/LoaderComponent.vue"
import { useAuth } from "./config/useAuth"
import { msalInstance, state, Auth } from "./config/msalConfig"
import { mapActions, mapGetters } from "vuex";
import { Types } from "./store/types";
import router from "./functions/router"

export default {
  name: "App",
  components: {
    snackbar: SnackbarComponent,
    AuthComponent,
    LoaderComponent,
    ModalLayout
  },
  data() {
    return {
      showBackButton: false,
      state,
      router,
      authenticationLoader: false,
      // debugLocalProperty: false,
      isProdLocalProperty: false
    };
  },
  computed: {
    ...mapGetters("projectList", {
      modalDetails: Types.getters.GET_MODAL_DETAIL
    }),
    ...mapGetters("middleware", {
      tncFlag: Types.getters.GET_TNC_CHECK,
    }),
    admin() {
      if(this.state.user && this.state.user.roles) {
        return this.state.user.roles.includes('admin')
      }
      return false
    },
    userId() {
      if(this.state.user) {
        return this.state.user.userid
      }
      return ''
    },
    userType() {
      if(this.state.user && this.state.user.me) {
        return this.state.user.me.userType
      }
      return ''
    },
    // debugMode: {
    //   get: function() {
    //     return this.debugLocalProperty
    //   },
    //   set: function(value) {
    //     this.debugLocalProperty = value
    //   }
    // }
    isProd: {
      get : function() {
        return this.isProdLocalProperty
      },
      set: function(value) {
        this.isProdLocalProperty = value
      }
    }
  },
  watch: {
    $route: function (newValue) {
      if (newValue.name === "PromptDetailsPage") {
        this.showBackButton = true;
      } else {
        this.showBackButton = false;
      }
    },
  },
  async mounted() {
    this.$root.snackbar = this.$refs.snackbar
    this.authenticationLoader = true 
    this.handleEnvironments()
    const { handleRedirect } = useAuth()
    await this.initialize()
    await handleRedirect()
    const accessToken = await Auth.getCachedToken("accessToken");
    await this.getTncFlag({ accessToken })
    const tncFlagTemp = false;
    if (tncFlagTemp) {
    // if (this.tncFlag) {
      this.updateModalDetail({
        showDialog: true,
        name: 'tncpopup',
        errorMessage: '',
      })
    }
    this.authenticationLoader = false
    this.debugMode = !!(JSON.parse(sessionStorage.getItem('debugBackendSession')))
  },
  methods: {
    ...mapActions("middleware", {
      getTncFlag: Types.actions.DO_TNC_CHECK
    }),
    ...mapActions("projectList", {
      updateModalDetail: Types.actions.DO_MODAL_DETAIL
    }),
    goBack() {
      this.$router.go(-1);
    },
    handleLogout() {
      this.authenticationLoader = true 
      const { logout } = useAuth()
      logout()
      this.authenticationLoader = false
    },
    async initialize() {
      try {
        await msalInstance.initialize()
      } catch (error) {
        console.log('Initialization error', error)
      }
    },
    handleEnvironments() {
      if (window.location.origin === 'https://sean.ey.com') {
        this.isProd = true;
      } else {
        this.isProd = false
      }
    },
    refreshPage() {
      location.reload();
    },
  },
}
</script>

<style>
.hidden {
    display: none;
}
.theme--dark.v-app-bar.v-toolbar.v-sheet {
    background-color: #2e2e38;
}
</style>
