import { HTTP, HTTP_BRAIN } from "./../../../functions/http-common";
import { Types } from "./../../types";
import ExcelInteraction from "../../../functions/excel";
const excel = new ExcelInteraction();

export const actions = {
  [Types.actions.DO_IS_LOADING]: ({ commit }, payload) => {
    commit(Types.mutations.SET_LOADING_STATUS, payload);
  },
  [Types.actions.DO_UPDATE_SNACKBAR]: ({ commit }, payload) => {
    commit(Types.mutations.SET_SNACKBAR, payload);
  },
  [Types.actions.DO_MODAL_DETAIL]: ({ commit }, payload) => {
    commit(Types.mutations.SET_MODAL_DETAIL, payload);
  },
  [Types.actions.DO_PROJECT_DETAIL]: async ({ dispatch, commit }, payload) => {
    const { projectId, loader, tableId, prompts } = payload;
    if (loader) commit(Types.mutations.SET_LOADING_STATUS, { global: true });
    return HTTP.get(`/projects/${projectId}`)
      .then(async (result) => {
        // Get the createdBy email from the collections
        const collections = result.data.collections;
        // Iterate over the collections array
        for (let i = 0; i < collections.length; i++) {
          // Get the createdBy email from the collection
          const createdByEmail = collections[i].createdBy;
          // Make a GET request to the Microsoft Graph API to get the display name
          try {
            const response = await HTTP.get(`https://graph.microsoft.com/v1.0/users/${createdByEmail}`);
            // Add the displayName as a new key 'name' to the collection
            collections[i].name = response.data.displayName;
        } catch (error) {
          console.error(error,"while fetching user display name through email id");
        }
    }
    //To add missing user name in userlist
    const users = result.data.users;
    for (let i = 0; i < users.length; i++) {
      if(users[i].userId && !users[i].displayName) {
        try {
          const response = await HTTP.get(`https://graph.microsoft.com/v1.0/users/${users[i].userId}`);
          users[i].displayName = response.data.displayName;
        } catch (error) {
          console.error(error,"while fetching user display name through email id");
        }
      }
    }
    result.data.collections = collections;
    result.data.users = users;
      commit(Types.mutations.SET_PROJECT_DETAIL, result.data);
        if (loader) {
          if (
            result.data &&
            result.data.contextTables &&
            result.data.contextTables.length
          ) {
            dispatch(Types.actions.DO_GET_TABLES, { projectId, tableId, prompts });
            commit(Types.mutations.SET_LOADING_STATUS, { global: false });
          } else {
            commit(Types.mutations.SET_TABLES, { data: [] });
            commit(Types.mutations.SET_LOADING_STATUS, { global: false });
          }
        }
        commit(Types.mutations.SET_LOADING_STATUS, { global: false });
      })
      .catch(() => {
        commit(Types.mutations.SET_TABLES, { data: [] });
        commit(Types.mutations.SET_LOADING_STATUS, { global: false });
      });
  },
  [Types.actions.DO_DELETE_PROJECT]: ({ commit }, payload) => {
    const { url } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, { global: true });
    /* eslint-disable */
    HTTP.delete(url)
      .then((result) => {
        commit(Types.mutations.SET_LOADING_STATUS, { global: false });
        commit(Types.mutations.SET_PROJECT_DETAIL, {});
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: "DeleteProjectModal",
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, { global: false });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          name: "DeleteProjectModal",
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_DOCUMENT]: ({ dispatch, commit }, payload) => {
    const { projectId, params } = payload;
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "DeleteDocumentModal",
    });
    commit(Types.mutations.SET_LOADING_STATUS, { collection: true });
    /* eslint-disable */
    HTTP_BRAIN.post(`/deleteFiles`, params)
      .then((result) => {
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          errorMessage: "Something went wrong! Please try again",
          name: "DeleteDocumentModal",
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_USER]: ({ commit, state }, payload) => {
    const { projectId, userId } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, { user: true });
    /* eslint-disable */
    HTTP.delete(`/projects/${projectId}/users?delUser=${userId}`)
      .then((result) => {
        const updatedUsers = state.projectDetail.users.filter(
          (item) => item.userId !== userId
        );
        commit(Types.mutations.SET_PROJECT_DETAIL, {
          ...state.projectDetail,
          users: updatedUsers,
        });
        commit(Types.mutations.SET_LOADING_STATUS, { user: false });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: "DeleteUserModal",
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, { user: false });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "Something went wrong! Please try again",
          name: "DeleteUserModal",
        });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_TABLE]: ({ dispatch, commit }, payload) => {
    const { projectId, url } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, {
      tableListUpdate: true,
      global: false,
    });
    /* eslint-disable */
    HTTP.delete(url)
      .then((result) => {
        dispatch(Types.actions.DO_GET_TABLES, { projectId });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: "DeleteUserModal",
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "Something went wrong! Please try again",
          name: "DeleteUserModal",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { tableListUpdate: false });
        console.log("error", error);
      });
  },
  [Types.actions.DO_DELETE_RESULT]: async ({ dispatch, commit }, payload) => {
    const { params, projectId, url } = payload;
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "DeleteResultModal",
    });
    commit(Types.mutations.SET_LOADING_STATUS, { result: true });
    /* eslint-disable */
    return HTTP.delete(url)
      .then((result) => {
        dispatch(Types.actions.DO_UPDATE_TABLE_STATUS, {
          projectId,
          tableId: params.tableId,
        });
        commit(Types.mutations.SET_LOADING_STATUS, { result: false });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          errorMessage: "Something went wrong! Please try again",
          name: "DeleteResultModal",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { result: false });
        console.log("error", error);
      });
  },
  [Types.actions.DO_ADD_USER]: ({ dispatch, commit, state }, payload) => {
    const { projectId, requestBody, actionType } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, { user: true });
    /* eslint-disable */
    HTTP.post(`/projects/${projectId}/users`, requestBody).then(
      (response) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
        });
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });
        commit(Types.mutations.SET_LOADING_STATUS, { user: false });
        state.snackbar.showSnackbar({
          message: `User ${actionType} Successfully`,
          color: "success",
        });
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, { user: false });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        console.log(error);
      }
    );
  },
  [Types.actions.DO_GET_TABLES]: async({ commit }, payload) => {
    const { projectId, tableId, uploadTable, prompts, editMode } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, { tableListUpdate: true });
    return HTTP.get(`/projects/${projectId}/tables`)
      .then((result) => {
        commit(Types.mutations.SET_TABLES, {
          data: result.data,
          tableId,
          projectId,
          uploadTable,
          prompts,
          editMode 
        });
        commit(Types.mutations.SET_LOADING_STATUS, { tableListUpdate: false });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { tableListUpdate: false });
        console.error("Error adding table:", error);
      });
  },
  [Types.actions.DO_UPLOAD_TABLE]: ({ dispatch, commit, state }, payload) => {
    const { tableData, editMode } = payload;
    const { projectId } = tableData
    commit(Types.mutations.SET_LOADING_STATUS, { tableListUpdate: true });
    /* eslint-disable */
    HTTP.post(`/projects/${projectId}/tables`, tableData)
      .then((response) => {
        dispatch(Types.actions.DO_GET_TABLES, {
          projectId: projectId,
          uploadTable: true,
          editMode
        });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
        });
        state.snackbar.showSnackbar({
          message: "Table Uploaded Successfully",
          color: "success",
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
        });
        state.snackbar.showSnackbar({
          message: "Table failed to upload as API call failed",
          color: "error",
          icon: "mdi-alert",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { tableListUpdate: false });
        console.error("Error adding table:", error);
      });
  },
  [Types.actions.DO_UPDATE_RESULT]: ({ commit, state }, payload) => {
    const { params, pipeline } = payload;
    if (pipeline === "tableCommentary") {
      commit(Types.mutations.SET_LOADING_STATUS, { tableCommentary: true });
    } else {
      commit(Types.mutations.SET_LOADING_STATUS, { tableResultPipeline: true });
    }
    return HTTP.post(`/queue/${pipeline}`, params).then(
      (response) => {
        state.snackbar.showSnackbar({
          message: `Updated ${pipeline} Successfully`,
          color: "success",
        });
        commit(Types.mutations.SET_LOADING_STATUS, {
          tableResultPipeline: false,
          tableCommentary: false,
        });
      },
      (error) => {
        state.snackbar.showSnackbar({
          message: "API call failed",
          color: "error",
          icon: "mdi-alert",
        });
        commit(Types.mutations.SET_LOADING_STATUS, {
          tableResultPipeline: false,
          tableCommentary: false,
        });
        console.log(error);
      }
    );
  },
  
  [Types.actions.DO_GET_DOCUMENT]: ({ commit }, payload) => {
    commit(Types.mutations.SET_DOCUMENTS, []);
    commit(Types.mutations.SET_LOADING_STATUS, { document: true });
    /* eslint-disable */
    HTTP.get(`/projects/${payload}/documents`).then(
      (response) => {
        commit(Types.mutations.SET_LOADING_STATUS, { document: false });
        commit(Types.mutations.SET_DOCUMENTS, response.data);
      },
      (error) => {
        commit(Types.mutations.SET_LOADING_STATUS, { document: false });
        console.log(error);
      }
    );
  },
  [Types.actions.DO_UPDATE_COLLECTION]: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      commit(Types.mutations.SET_LOADING_STATUS, { collection: true });
      commit(Types.mutations.SET_COLLECTIONS, []);
      /* eslint-disable */
      HTTP_BRAIN.post("/usercollectionlist", {}).then(
        (response) => {
          commit(Types.mutations.SET_COLLECTIONS, response.data);
          commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
          resolve(response.status);
        },
        (error) => {
          commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
          let status;
          let errorMessage = error.response.data.error_message || "API call failed";
          if (error.response) {
            status = error.response.status;
          } else {
            status = 500;
          }
          state.snackbar.showSnackbar({
            message: errorMessage ,
            color: "error",
            icon: "mdi-alert",
          });
          console.log("error", error);
          resolve(status);
        }
      );
    });
  },
  [Types.actions.DO_UPDATE_SELECTED_ROW]: ({ commit }, payload) => {
    commit(Types.mutations.SET_SELECTED_ROW, payload);
  },
  [Types.actions.DO_UPDATE_TABLE_STATUS]: ({ commit, state }, payload) => {
    const { tableId, projectId } = payload;
    let resultStatus = {};
    /* eslint-disable */
    return HTTP.post("/projects/table/status", { tableId, projectId }).then(
      (response) => {
          Object.entries(response.data).forEach((status) => {
            const [key, value] = status;
            if (response.data[key].queued) {
              resultStatus[key] = {
                ...value,
                results: {
                  ...value.results,
                  status: "queued",
                },
              };
            } else
              resultStatus[key] = {
                ...value,
                results:
                  value.results &&
                  value.results.length &&
                  value.results
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .find(
                      (item) =>
                        item.status === "finished" || item.status === "running"
                    ),
              };
          });
          if (JSON.stringify(state.tableStatus) !== JSON.stringify(response.data)) {
            commit(Types.mutations.SET_RESULT_STATUS, resultStatus);
            commit(Types.mutations.SET_RESULT_LIST, response.data);
            commit(Types.mutations.SET_TABLE_STATUS, response.data);
          }
      },
      (error) => {
        console.log(error);
      }
    );
  },
  [Types.actions.DO_UPDATE_SETTING]: ({ commit }, payload) => {
    commit(Types.mutations.SET_UPDATE_SETTING, payload);
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "updateSettingModal",
    });
  },

  [Types.actions.DO_ADJUST_RESULT]: async (
    { dispatch, commit, state },
    payload
  ) => {
    const { projectId, tableId, resultId, extractionId } = payload;
    /* eslint-disable */
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
    });
    commit(Types.mutations.SET_LOADING_STATUS, { result: true });
    await HTTP.post(`/projects/${projectId}/adjustment`, payload)
      .then((response) => {
        console.log("Results adjustment payload: ", response);
        dispatch(Types.actions.DO_UPDATE_TABLE_STATUS, { projectId, tableId });
        state.snackbar.showSnackbar({
          message: "Adjustments Uploaded Successfully",
          color: "success",
        });
        if (!extractionId) {
          excel.updateExcelNameAdjustments(
            tableId,
            resultId,
            response.data.item.adjustmentId
          );
        }
        commit(Types.mutations.SET_LOADING_STATUS, { result: false });
        return response.data.item.adjustmentId;
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          errorMessage: "Something went wrong! Please try again.",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { result: false });
        console.error("Error uploading results adjustment:", error);
      });
  },
  [Types.actions.DO_REVERT_RESULT]: async (
    { dispatch, commit, state },
    payload
  ) => {
    const { url, revertPayload } = payload;
    const { projectId, tableId } = revertPayload;
    commit(Types.mutations.SET_LOADING_STATUS, { result: true });
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
    });
    /* eslint-disable */
    await HTTP.delete(url)
      .then((response) => {
        dispatch(Types.actions.DO_UPDATE_TABLE_STATUS, { projectId, tableId });
        state.snackbar.showSnackbar({
          message: "Adjustments Reverted Successfully",
          color: "success",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { result: false });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          errorMessage: "Something went wrong! Please try again.",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { result: false });
        console.error("Error reverting results adjustment:", error);
      });
  },
  [Types.actions.DO_RELATIONSHIPS]: ({ commit, dispatch }, payload) => {
    const { projectId, params, prompts } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, { tableRelationship: true });
    commit(Types.mutations.SET_MODAL_DETAIL, {
      name: "AddTableModal",
      showDialog: false,
      errorMessage: "",
    });
    HTTP.post(
      `/projects/${projectId}/relationships?tableId=${params.tableId}`,
      params
    ).then((response) => {
      commit(Types.mutations.SET_TABLE_RELATIONSHIPS, response.data);
      dispatch(Types.actions.DO_GET_TABLES, {
        projectId,
        tableId: params.tableId,
        prompts: prompts,
      });
      commit(Types.mutations.SET_LOADING_STATUS, { tableRelationship: false });
    }),
      (error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          errorMessage: "Something went wrong! Please try again.",
          name: "AddTableModal",
        });
        commit(Types.mutations.SET_LOADING_STATUS, {
          tableRelationship: false,
        });
        console.error(error);
      };
  },
  [Types.actions.DO_DELETE_RELATIONSHIP]: (
    { commit, dispatch },
    payload
  ) => {
    const { projectId, tableId, delRelationships, prompts } = payload;
    let url = `/projects/${projectId}/relationships?delTable=${tableId}&delRelationship=${delRelationships}`;
    commit(Types.mutations.SET_LOADING_STATUS, { tableRelationship: true });
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "DeleteTableModal",
    });
    HTTP.delete(url)
      .then((response) => {
        dispatch(Types.actions.DO_GET_TABLES, { projectId, tableId, prompts });
        commit(Types.mutations.SET_LOADING_STATUS, {
          tableRelationship: false,
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          name: "DeleteTableModal",
          errorMessage: error.toString(),
        });
        commit(Types.mutations.SET_LOADING_STATUS, {
          tableRelationship: false,
        });
      });
  },

  [Types.actions.DO_ADD_TO_PROJECT]: ({ dispatch, commit }, payload) => {
    const { projectId, collectionData } = payload;
    const requestData = {
      collectionIds: collectionData.map((item) => ({
        collectionId: item.collectionId,
        displayName: item.displayName,
      })),
    };
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "DocumentModal",
    });
    commit(Types.mutations.SET_LOADING_STATUS, { collection: true });
    HTTP.post(`/projects/${projectId}/collections`, requestData)
      .then((response) => {
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });

        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
      })
      .catch((error) => {
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
          name: "DocumentModal",
        });
      });
  },
  [Types.actions.DO_DELETE_COLLECTION]: ({ commit, dispatch }, payload) => {
    const { projectId, collectionId } = payload;
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "DeleteCollectionModal",
    });
    commit(Types.mutations.SET_LOADING_STATUS, { collection: true });
    /* eslint-disable */
    HTTP.delete(
      `/projects/${projectId}/collections?delCollection=${collectionId}`
    )
      .then((result) => {
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });

        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          errorMessage: "Something went wrong! Please try again",
          name: "DeleteCollectionModal",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
        console.log("error", error);
      });
  },
  [Types.actions.DO_CREATE_COLLECTION]: (
    { dispatch, commit },
    payload
  ) => {
    return new Promise((resolve, reject) => {
      const { projectId, requestBody } = payload;
      commit(Types.mutations.SET_MODAL_DETAIL, {
        showDialog: false,
        errorMessage: "",
        name: "DeleteCollectionModal",
      });
      commit(Types.mutations.SET_LOADING_STATUS, { collection: true });
      HTTP_BRAIN.post(`/uploadfiles`, requestBody).then(
        (response) => {
          const { collection_id } = response.data;
          const requestData = {
            collectionIds: [
              {
                collectionId: collection_id,
                displayName: collection_id.split("_")[1],
              },
            ],
          };
          dispatch(Types.actions.DO_DOC_TO_PROJECT, { projectId, requestData });
          commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
          resolve(response.data.collection_id);
        },
        (error) => {
          commit(Types.mutations.SET_LOADING_RESULT_STATUS, false);
          commit(Types.mutations.SET_MODAL_DETAIL, {
            errorMessage: "Something went wrong! Please try again.",
            name: "DocumentModal",
          });
          commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
          console.log(error);
          reject(error);
        }
      );
    });
  },
  [Types.actions.DO_DOC_TO_PROJECT]: ({ dispatch, commit }, payload) => {
    const { projectId, requestData } = payload;
    HTTP.post(`/projects/${projectId}/collections`, requestData)
      .then((response) => {
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });
        console.log("Document Update successful", response);
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
          name: "DocumentModal",
        });
        console.error("Error Updating Document", error);
      });
  },
  [Types.actions.DO_APPLY_OWN_RESULT]: async (
    { commit, state },
    payload
  ) => {
    const { projectId, tableId, requestBody } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, {
      tableOwnResultPipeline: true,
    });
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "EditOwnResult",
    });
    return HTTP.post(`/projects/${projectId}/result`, requestBody)
      .then((response) => {
        state.snackbar.showSnackbar({
          message: `Own Result updated Successfully`,
          color: "success",
        });
        commit(Types.mutations.SET_LOADING_STATUS, {
          tableOwnResultPipeline: false,
        });
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: true,
          errorMessage: "Something went wrong! Please try again.",
          name: "EditOwnResult",
        });
        commit(Types.mutations.SET_LOADING_STATUS, {
          tableOwnResultPipeline: false,
        });
        console.error("Error Updating Document", error);
      });
  },
  [Types.actions.DO_MODIFY_COLLECTION]: (
    { dispatch, commit },
    payload
  ) => {
    const { projectId, requestBody } = payload;
    commit(Types.mutations.SET_MODAL_DETAIL, {
      showDialog: false,
      errorMessage: "",
      name: "EditCollectionModal",
    });
    commit(Types.mutations.SET_LOADING_STATUS, { collection: true });
    HTTP_BRAIN.post(`/addFiles`, requestBody).then(
      (response) => {
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });
        console.log("Document Update successful", response);
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
      },
      (error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          errorMessage: "Something went wrong! Please try again.",
          name: "DocumentModal",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
        console.log(error);
      }
    );
  },
  [Types.actions.DO_FAVOURITE_COLLECTION]: (
    { dispatch, commit, state },
    payload
  ) => {
    const { projectId, collectionId } = payload;
    commit(Types.mutations.SET_LOADING_STATUS, { collection: true });
    /* eslint-disable */
    HTTP.post(`/projects/${projectId}/favourite`, { collectionId }).then(
      (response) => {
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
      },
      (error) => {
        state.snackbar.showSnackbar({
          message: "API call failed",
          color: "error",
          icon: "mdi-alert",
        });
        commit(Types.mutations.SET_LOADING_STATUS, { collection: false });
        console.log(error);
      }
    );
  },
  [Types.actions.DO_FETCH_USERS]: async ({ state }, searchQuery) => {
    /* eslint-disable */
    try {
      const response = await HTTP.get(
        `https://graph.microsoft.com/v1.0/me/people?$search="${searchQuery}"`
      );
      return response.data.value;
    } catch (error) {
      state.snackbar.showSnackbar({
        message: "Can't search user as API call failed",
        color: "error",
        icon: "mdi-alert",
      });
      console.error("Error fetching users", error);
    }
  },
  [Types.actions.DO_FEED_BACK]: async ({ state, commit }, payload) => {
    const { url, feedback, resultId ,extractionId, feedbackText} = payload;
    let requestPayload = "";
    if (extractionId) {
      if (feedback) {
        requestPayload = { feedback, resultId, extractionId }
      } else {
        requestPayload = { feedbackText, resultId, extractionId }
      }
    } else{
      if (feedback) {
        requestPayload = { feedback, resultId }
      } else {
        requestPayload = { feedbackText, resultId }
      }
    }
    commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: "FeedbackModal",
        }); 
    /* eslint-disable */
    return HTTP.post(url, requestPayload)
      .then((result) => {
        console.log('updated feedback result', result)
        state.snackbar.showSnackbar({
          message: "Feedback updated successfully",
          color: "success",
          icon: "",
        });
      })
      .catch((error) => {
        state.snackbar.showSnackbar({
          message: "Can't update feedback as API call failed",
          color: "error",
          icon: "mdi-alert",
        });
        console.error("Error fetching users", error);
      });
  },
  [Types.actions.DO_ADD_TAGS]: ({ commit, dispatch, state }, payload) => {
    const { projectId, requestData } = payload;
    HTTP.post(`/projects/${projectId}/tags`, requestData)
      .then((response) => {    
        dispatch(Types.actions.DO_PROJECT_DETAIL, {
          projectId,
          loader: false,
        });    
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: "TagsDocumentModal",
        }); 
      })
      .catch((error) => {
        commit(Types.mutations.SET_MODAL_DETAIL, {
          showDialog: false,
          errorMessage: "",
          name: "TagsDocumentModal",
        });
        state.snackbar.showSnackbar({
          message: "Error adding Tags. Please try again.",
          color: "error",
          icon: "mdi-alert",
        });
      });
  }
};
